@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0a0a0a;
}

.loading {
  text-align: center;
  color: white;
}

.loading img {
  width: 60px;
  margin: 30px;
  animation: rotate 2s linear infinite;
}

.loading p {
  margin: 0;
}

@media (max-width: 768px) {
  .loading {
    padding: 0 1rem 0 6rem;
    flex-direction: column;
  }
}
