

*{
    margin: 0;padding: 0;box-sizing: border-box;
  }

  body{
   background-color: #eaeaea;;
  }
  .dropdown-toggle::after {
    display: none;
  }
  
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
  }
  


.nav-item .Selectedddddd {
    color: #ff0000 !important; 
}
.sidebar-nav li a .Selectedddddd{
  color: #ff0000 !important; 
}


.Selectedddddd{
color:  #ff0000 !important; 
}




  .navbar {
    padding-top: 0rem; 
    padding-bottom: 0rem;
  }


  .img{
    width:20%;
  }
  
  .bg-light {
    background-color: #f9f9f9 !important;
  }

  h1{
    color: aqua;
  }
  
  #wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  
  #wrapper.toggled {
    padding-left: 250px;
    overflow: hidden;
  }
  
  #sidebar-wrapper {
    z-index: 1000;
    position: absolute;
    left: 250px;
    width: 0;
    margin-left: -250px;
    /* overflow-y: auto;*/
    background: #ffffff; 
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  
  #page-content-wrapper {
    position: absolute;
    padding: 15px;
    width: 100%;
    overflow-x: hidden;
  }
  
  .xyz {
    min-width: 360px;
  }
  
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0px;
  }
  
  .fixed-brand {
    width: auto;
  }
  
  .myinfo{
    display: flex;
      align-content: flex-start;
      align-items: stretch;
  }
  
  .information{
    font-size: 12px;
  }
  #name{
    color:black;
    font-weight: bold;
  }
  #status{
    color:#999999;
    text-align: center;
  }
  /* Sidebar Styles */
  #wrapper {
    display: flex;
    flex-wrap: nowrap;
  }
  .sidebar-nav {
    /* position: absolute; */
    top: 0;
    /* width: 250px; */
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 2px;
    height: 100vh;
  }
  
  .sidebar-nav li {
    /* text-indent: 15px; */
    line-height: 40px;
  }
  
  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
  }

  .icon{
    color: #999999;
    cursor: pointer;
  }
  
  /* .sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    border-left: red 2px solid;
  } */
  
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
  }
  
  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
  }
  
  .sidebar-nav > .sidebar-brand a {
    color: #999999;
  }
  
  .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
  }
  
  .no-margin {
    margin: 0;
  }
  
  .navbar-toggler {
    display:none;
  }
  .fixed-brand {
    width: 250px;
  }
  #sidebar-wrapper {
    width:75px;
  }
  
  .content-section{
    background-color:#eaeaea;
    /* height: 100vh; */
    padding-left: 7rem;
  }
  
  .side-icon{
    font-size: 30px !important;
  }
  .sidebar-nav li a :hover{
    color: #ff0000;
  }
  
  /* search-bar */
  
  .search{
    /* width: 30%; */
    /* float: right;  */
    display: flex;
    justify-content: flex-end;
  }
  .search-bar {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30%;
  }
  
  .search-bar input[type="text"] {
    border: none;
    padding: 8px;
    flex-grow: 1;
    margin-right: 4px;
    width: 30%;
    background:#f9f9f9 !important;
  }
  
  .search-bar button {
    background: #f9f9f9;
    border: none;
    padding: 8px;
  }
  
  /* Font Awesome icon style */
  .fas.fa-search {
    color: #ff0000;
  }

  /* Custom styles for Dropdown.Toggle */
.custom-dropdown-toggle {
  background-color: transparent; /* Remove default background color */
  border: none; /* Remove border */
  color: inherit; /* Use inherited text color */
  box-shadow: none; /* Remove any shadow */
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:active,
.show>.btn-primary.dropdown-toggle,
btn-primary:active:focus  {
  background-color: transparent; /* Remove default background color */
  border: none; /* Removes border on hover/focus/active */
  color: inherit; /* Use inherited text color */
  box-shadow: none !important; /* Remove any shadow */
  outline: none !important; /* Removes focus outline on focus/active */
}
.dropdown-item:active {
  /* color: #fff; */
  /* text-decoration: none; */
  background-color: #ff0000;
}


.position-fixed{
  position: fixed;
  height: 100vh;
}
.nav-position{
  position: fixed;
  width: 100%;
  z-index: 1;
}
  
  @media(max-width:768px){
    .img{
      width: 40%;
    }
    #sidebar-wrapper {
      width: 68px;
    }
    .search-bar{
      width: 100%;
    }
    /* */
  }
  
  @media (min-width: 768px) {
    #wrapper {
       padding-left: 250px;
    }
    
    #wrapper.toggled {
       padding-left: 0;
    }
    /* #sidebar-wrapper {
       width: 250px;
    } */
    #wrapper.toggled #sidebar-wrapper {
       width: 250px;
    }
    #wrapper.toggled-2 #sidebar-wrapper {
       width: 50px;
    }
    #wrapper.toggled-2 #sidebar-wrapper:hover {
       width: 250px;
    }
    #page-content-wrapper {
       padding: 20px;
       position: relative;
       -webkit-transition: all 0.5s ease;
       -moz-transition: all 0.5s ease;
       -o-transition: all 0.5s ease;
       transition: all 0.5s ease;
    }
    #wrapper.toggled #page-content-wrapper {
       position: relative;
       margin-right: 0;
       padding-left: 250px;
    }
    #wrapper.toggled-2 #page-content-wrapper {
       position: relative;
       margin-right: 0;
       margin-left: -200px;
       -webkit-transition: all 0.5s ease;
       -moz-transition: all 0.5s ease;
       -o-transition: all 0.5s ease;
       transition: all 0.5s ease;
       width: auto;
    }
  
   
  }
  