* {
    font-family: 'Poppins', sans-serif;
  }
  
  .content {
    padding: 20px;
    background-color: #eaeaea;
    height: 100vh;
  }
  .interest-card-body{
    border: transparent;
    border-radius: 25px;
    background-color: white;
    padding: 25px;
    margin: 35px;
  }
  .header-interest {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .main-add {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .btn-select-mul {
    display: block;
    width: 100%;
    padding: 10px 15px;
    margin: 5px 0;
    border: none;
    background-color: rgb(227, 227, 227);
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-select-mul.selected {
    background-color: #ff0000;
    color: white;
  }
  .interest-submit-btn{
    text-align: right;
    margin-right: 20px;
  }
  .btn-start-interest {
    padding: 10px 20px;
    margin-bottom: 15px;
    border: none;
    background-color: #ffffff;
    color: rgb(255, 0, 0);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-start-interest:hover {
    background-color: #ff0000;
    color: white;
  }
  
  @media (max-width: 768px) {
    .btn-select-mul {
      font-size: 7px;
    }
    .interest-card-body{
      padding: 15px;
      margin: 15px 0px;
    }
    .interest-form-sample{
      padding: 10px;
    }
  }
  