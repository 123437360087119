.m-row{
    background-color: #ffffff;
    border-radius: 12px;
    padding: 10px;
}

#reports{
    text-align: center;
}
.pdf-img{
    width: 25px;
    height: 30px;
}


.lab-btn{
    background-color: #ffffff;
    color:#ff0000;
    border: none;
    padding: 8px;
    font-size: 22px;
    font-weight:600;
    width: 65%;
    border-radius: 8px;
}

.highlight {
    border: 2px solid red;
  }

  
.lab{
    border: none;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
}

.column{
    margin-top: 15px;
}

.payment-btn{
    background-color: #ffffff;
    color:#ff0000;
    border: none;
    padding: 8px;
    font-size: 22px;
    font-weight:600;
    width: 30%;
    border-radius: 8px;
}


@media(max-width:600px){
    .btn{
        width: 50%;
    }
    .colmar{
        margin-top: 0 !important;
    }
}

@media(max-width:600px){
    .btn{
        width: 100%;
    }
    #sidebar-wrapper{
        height: 100vh;
    }
    .book-content-section{
        padding-left: 5rem;
        padding-right: 1rem !important;
    }
    .lab-content-section{
        padding-left: 6rem;
        padding-right: 2rem !important;
    }
}