.search-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
}

.search-bar-rep{
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30%;
    height: 40px;
}

.search-bar-rep button {
    background: #f9f9f9;
    border: none;
    padding: 8px;
}

.search-bar-rep input[type="text"] {
    border: none;
    padding: 8px;
    flex-grow: 1;
    margin-right: 4px;
    width: 30%;
    background: #f9f9f9 !important;
}
.circle{
    background: red;
    color: white;
    width: 54px;
    height: 52px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.circle-radius{
    border: 1px solid red;
    border-radius: 50%;
    padding: 3px;
}
.css-1hs7rf2-JoyCircularProgress-progress {
--CircularProgress-progressColor: #f80000 !important;
}
.progress-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
.diseases{
    display: flex;
    
    justify-content: space-between;
    margin: 15px 0;
}
.disease{
    display: flex;
}
.info-disease,.disease-img{
    background-color: #ffffff;
    padding: 4px;

}

.name,.percentage,.is-match{
    margin-bottom: 0rem !important;
}

.percentage{
    font-size: 25px;
    font-weight: 600;
}

.name,.is-match{
    font-size: 12px;
    font-weight: 600;
}
.disease-img {
    display: flex;
    /* flex-wrap: nowrap; */
    /* justify-content: flex-start; */
    align-items: center;
    
}
.disease-image{
    width: 43px;
    height: 36px;
}

.image-radius{
    border: 2px solid red;
    padding: 8px;
    border-radius: 50%;
    /* width: 8px; */
}

.info-disease-diff,.disease-img-diff{
    background-color: #f47575;
    padding: 4px;

}
.disease-img-diff {
    display: flex;
    align-items: center;
}

.percentage,.is-match{
    text-align: center;
}
.extra{
    visibility: hidden;
}

.extra-space{
    position: relative;
    left: 25%;
}

.text_{
    
        margin-bottom: 0rem;
        font-size: 11px;
        font-weight: 600;
}
/* graphhhh */
#chart1, #chart2 {
    width: 100%;
    height: 100%;
}
.row-head {
    display: flex;
    /* flex-wrap: nowrap;
    width: 40%; */
  }
  .chart-row {
    display: flex;
    justify-content: space-between;
  }
  /* .cell {
    padding: 0px 35px;
  } */
  /* .container {
    width: 80%;
    margin: 15px auto;
  } */
  .sd-line{
    --line: var(--_line-bottom-color) var(--_line-start), #f70707 var(--_line-start), #fa0404 var(--_line-end), white 0%;
    --_point-color: var(--point-color, #f80000);
    --_point-border-color: var(--point-border-color, #ff0606);
    --_point-border-width: var(--point-border-width, var(--line-size));
  }
  .sd-chart{
  --axis-line-color: #5c5c5c;
    --grid-line-color: #fff;
    --label-color: #4e4e4e;
    --grid-line-size: 1px;
    --axis-line-size: 1px;
    --label-distance: 10px;
    --line-size: 2px;
    --line-color: #ff0010;
    --point-radius: 6px;
    --point-color: inherit;
    --point-border-color: inherit;
    --point-border-width: inherit;
    --dot-radius: calc(var(--point-radius) / var(--dot-ratio, 1));
    --point-inner-color: transparent;
    --bar-color: #ff0000;
    --area-color: #6d81f04d;
    --area-top-color: transparent;
}
/* .graphhhh{
    border: transparent;
    background-color: white;
    border-radius: 20px;
    width: 45%;
    margin: 25px 12px;
    margin-bottom: 10px;
    height: 100%;
    padding: 30px;
} */
.graph-row{
    justify-content: space-between;
}
/* chart */
/* header{
    font-size: 20px;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
  } */
.chart{
    border: transparent;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    margin-right: 25px;
    margin-bottom: 10px;
    height: 100%;
    padding: 15px;
}



.info_{
    font-size: 13px;
    margin-bottom: 1px;
    font-weight: bold;
    margin-bottom: 15px;
}
.info_p{
    font-size: 13px;
    margin-bottom: 1px;
    font-weight: bold;
}
.p-r{
    margin-bottom: 0rem !important;
    font-weight: bold;

}
.progress-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff0000;
    transition: width .6s ease;
}
.progress {
    display: flex;
    height: 0.5rem;
    width: 85%;
    overflow: hidden;
    /* font-size: 7px; */
    background-color: #fd739d2e;
    margin: 3px;
    border-radius: 0;
}

/* .prdt-img{
    padding-left: 20px;
} */
.row-heading{
    display: none;
}
.buy-btn{
    background-color: #f70707;
    color: white;
    display: flex;
    border: transparent;
    width: 20%;
    border-radius: 10px;
}

.buy-btn-sm {
    background-color: #f70707;
    color: white;
    display: flex;
    border: transparent;
    width: 9%;
    border-radius: 114px;
    font-size: 9px;
    margin-bottom: 10px;
    padding: 1px;
}
.btn-div{
    display: flex;
    justify-content: center;
}
.info_r{
    font-size: 10px;
    margin-bottom: 10px;
}

.medicine{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
@media(max-width:600px){
    .search-bar-rep{
        width: 100%;
      }
      .search-div {
        flex-direction: column;
        margin-top: 60px;
    }
    .complete-circle{
        margin-bottom: 10px;
    }

    
    .extra{
        /* visibility: hidden; */
        display: none;
    }
    .info-disease,.info-disease-diff{
        width: 100%;
    }

    .Activity-level-text{
        margin-top: 20px;
    }
    /* .graphhhh{ */
    /* min-width: 110%;
    margin-right: 25px;
    height: 100%;
    padding: 30px;
    margin-bottom: 10px; */
    /* border: transparent;
    background-color: white;
    border-radius: 20px; */
    /* width: 45%; */
    /* margin: 25px 12px;
    margin-bottom: 10px; */
    /* height: 100%; */
    /* padding: 30px; */

    /* -------------- */
    /* min-width: 93%;
    margin-right: 25px;
    height: 100%;
    padding: 30px;
    margin-bottom: 10px; */
    /* } */
    .row-head{
        display: none;
    }
    .row-heading{
        display: block;
    }
    .head-hr{
        display: none;
    }

    .buy-btn-sm {
        
        width: 25%;

    }

    .content-section {
        background-color: #eaeaea;
        /* height: 100vh; */
        /* padding-left: 5rem; */
    }
    .home-content-section {
        padding-left: 5rem;
        padding-right: 1rem !important;
    }
    /* .pe-5 {
        padding-right: 1rem !important;
    } */
    .lab-btn{
        font-size: 15px;
    }
    
}
.add-data-btn{
    color: green;
    font-size: 25px;
    cursor: pointer;
}

.take-lab-test-btn {
    padding: 10px 20px;
    border: none;
    background-color: #ffffff;
    color: rgb(255, 0, 0);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    width: 100%;
    border-radius: 5px;
    margin-top:10px;
    font-size: 25px;
  }
  
  .take-lab-test-btn:hover {
    background-color: #ff0000;
    color: white;
  }

  .css-19b9ae-JoyCircularProgress-root,
  .css-1jkzd2z-JoyCircularProgress-root,
  .css-1jran0a-JoyCircularProgress-root,
  .css-pij7xf-JoyCircularProgress-root,
  .css-821msl-JoyCircularProgress-root {
  
    --_track-thickness: var(--CircularProgress-trackThickness, var(--CircularProgress-thickness, 2px)) !important;
    --_progress-thickness: var(--CircularProgress-progressThickness, var(--CircularProgress-thickness, 2px)) !important;
   
}

.css-1hs7rf2-JoyCircularProgress-progress {
  
    stroke-width: var(--_progress-thickness) !important;
   
}



@media(max-width:768px){
    .diseases{
        display: flex;
         flex-direction: column;
         align-content: space-between;
    }
    .disease{
        margin-bottom: 20px;
    }
    .info-disease{
        width: 100%;
    }
}

@media(min-width:1250px){
    .info-disease{
        width: 125px;
    }
}