/* .mainnnnnnnnnnn {
  background-color: white;
  border-radius: 20px;
  width: 90%;
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
} */

.main-container{
  background-color: white;
  width: 75%;
  padding: 2%;
  border-radius: 15px;

}
.scale {
  width: 70%;
  background-color: #f1f2f3;
  margin: 20px 9px; /* Add margin to create space */
}

.row-rular {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-3 {
  flex: 1;
  text-align: center;
  /* margin-bottom: 20px;  */
}
.column-9 {
  flex: 6;
}

.image {
  width: 135px;
  height: 100px;
  object-fit: contain;
}

/* .img3{
  position: relative;
  right: 12px;
} */

.content-rular {
  padding-left: 8rem;
  background-color: #eaeaea;
  height: 100vh;
  
}

.btn-start {
  margin-bottom: 15px;
  padding: 10px 20px;
  background-color: white;
  color: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-start:hover {
  background-color: red;
  color: white;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: red;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

.label, .rular-output, .rular-option {
  color: red;
}

.mid {
  position: relative;
}

.mid::after {
  content: '▼';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid red;
}

@media (max-width: 768px) {
  .content-rular {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .image {
    width: 80px;
  }
  .scale {
    width: 100%;
  }
}